<template>
  <v-col cols="12" aut-static-seeddata-config>
    <v-switch
      dense
      aut-edit-static-seedata-text-toggle
      v-model="isKeyValue"
      :label="switchLabel"
      @change="handleChange"
      persistent-hint
      hint="Choose whether this static list should have id & names or just a simple list of textual content."
    ></v-switch>
    <div>
      <KeyValueList
        v-if="isKeyValue"
        :data="seedData.value"
        @update="updateItems"
      />
      <v-row dense v-else>
        <v-col cols="12">
          <v-text-field
            aut-static_option-text
            v-model="lookupText"
            ref="focus"
            autofocus
            hint="Enter data after comma and space."
          ></v-text-field>
        </v-col>
      </v-row>
    </div>
  </v-col>
</template>
<script>
import { isPlainObject } from "lodash";
import KeyValueList from "./KeyValueList.vue";
import { clone } from "@/util.js";
const debug = require("debug")("atman.components.static_seed"); // eslint-disable-line

export default {
  name: "StaticSeed",
  props: {
    data: {
      type: Object,
    },
  },
  components: {
    KeyValueList,
  },
  data() {
    const seedData = clone(this.data);
    seedData.value = seedData.value || [];
    return {
      isKeyValue:
        seedData.value.length > 0 && typeof seedData.value[0] != "string",
      seedData,
      errorMessages: {},
    };
  },
  computed: {
    lookupText: {
      get: function () {
        return this.seedData.value.join(", ");
      },
      set: function (getValue) {
        this.seedData.value = getValue.split(",").map((item) => {
          return item.trim();
        });
        this.updateDefinition();
      },
    },
    switchLabel() {
      return this.isKeyValue ? "ID & Name" : "Text";
    },
  },
  methods: {
    handleChange() {
      this.seedData.value = this.seedData.value.map((item) => {
        if (!this.isKeyValue && isPlainObject(item)) {
          return item.id;
        }
        return item;
      });
    },
    updateItems(items) {
      this.$set(this.seedData, "value", items);
      this.updateDefinition();
    },
    updateDefinition() {
      delete this.seedData.apis;
      this.$emit("update:seed_data", this.seedData);
    },
  },
};
</script>
